<template>
  <div>
    <div class="router-view-container">
      <div class="flex items-center space-x-4 mb-5">
        <h1 class="page_title">{{ $route.meta.title }}</h1>
      </div>
      <main class="space-y-5">
        <section class="grid grid-cols-1 md:grid-cols-1 gap-6">
          <ValidationObserver  v-slot="{ invalid }" tag="div" class="box flex flex-col">
            <div class="flex">
              <div class="mb-1 w-32">
                <h1 class="title">Banner電腦版(1920*530) <span>*</span></h1>
                <div class="upload aspect-ratio-1-1_global_style">
                  <img v-if="banner1" :src="banner1" class="uploadImg" />
                  <div v-else class="upload_icon">
                    <svg-icon icon-class="upload" className="gray-divide" style="width: 3rem; height: 3rem"></svg-icon>
                    <p class="text-gray-400 text-xs">點擊上傳或拖曳圖片</p>
                  </div>
                  <vs-input type="file" @change="uploadImg($event, 'banner1')" accept="image/.png,.jpg,.jpeg,.gif,.PNG,.JPG,.JPEG,.GIF" class="img_input" />
                </div>
                <ValidationProvider mode="aggressive" name="Banner電腦版" placeholder="請選擇Banner電腦版" rules="required" v-slot="{ errors }" tag="div" class="relative">
                  <vs-input class="w-full" v-model="banner1" style="display:none;"/>
                  <errorDiv>{{ errors[0] }}</errorDiv>
                </ValidationProvider>
              </div>
              <div class="ml-3 mb-1 w-32">
                <h1 class="title">Banner手機版(415*570) <span>*</span></h1>
                <div class="upload aspect-ratio-1-1_global_style">
                  <img v-if="banner2" :src="banner2" class="uploadImg" />
                  <div v-else class="upload_icon">
                    <svg-icon icon-class="upload" className="gray-divide" style="width: 3rem; height: 3rem"></svg-icon>
                    <p class="text-gray-400 text-xs">點擊上傳或拖曳圖片</p>
                  </div>
                  <vs-input type="file" @change="uploadImg($event, 'banner2')" accept="image/.png,.jpg,.jpeg,.gif,.PNG,.JPG,.JPEG,.GIF" class="img_input" />
                </div>
                <ValidationProvider mode="aggressive" name="Banner手機版" placeholder="請選擇Banner手機版" rules="required" v-slot="{ errors }" tag="div" class="relative">
                  <vs-input class="w-full" v-model="banner2" style="display:none;"/>
                  <errorDiv>{{ errors[0] }}</errorDiv>
                </ValidationProvider>
              </div>
            </div>
            <hr />
            <div>
              <h1 class="title">關於標題 <span>*</span></h1>
              <ValidationProvider mode="aggressive" name="關於標題" placeholder="請輸入關於標題" rules="required|max:15" v-slot="{ errors }" tag="div" class="relative">
                <vs-input class="w-full" v-model="about_title" />
                <errorDiv>{{ errors[0] }}</errorDiv>
              </ValidationProvider>
            </div>
            <div>
              <h1 class="title">關於描述 <span>*</span></h1>
              <ValidationProvider mode="aggressive" name="關於描述" placeholder="請輸入關於描述" rules="required|max:50" v-slot="{ errors }" tag="div" class="relative">
                <vs-input class="w-full" v-model="about_descs" />
                <errorDiv>{{ errors[0] }}</errorDiv>
              </ValidationProvider>
            </div>
            <div class="flex">
              <div class="mb-1 w-32">
                <h1 class="title">關於圖1(210*157) <span>*</span></h1>
                <div class="upload aspect-ratio-1-1_global_style">
                  <img v-if="about_img1" :src="about_img1" class="uploadImg" />
                  <div v-else class="upload_icon">
                    <svg-icon icon-class="upload" className="gray-divide" style="width: 3rem; height: 3rem"></svg-icon>
                    <p class="text-gray-400 text-xs">點擊上傳或拖曳圖片</p>
                  </div>
                  <vs-input type="file" @change="uploadImg($event, 'about_img1')" accept="image/.png,.jpg,.jpeg,.gif,.PNG,.JPG,.JPEG,.GIF" class="img_input" />
                </div>
                <ValidationProvider mode="aggressive" name="關於圖1" placeholder="請選擇關於圖1" rules="required" v-slot="{ errors }" tag="div" class="relative">
                  <vs-input class="w-full" v-model="about_img1" style="display:none;"/>
                  <errorDiv>{{ errors[0] }}</errorDiv>
                </ValidationProvider>
              </div>
              <div class="ml-3 mb-1 w-32">
                <h1 class="title">關於圖2(210*157) <span>*</span></h1>
                <div class="upload aspect-ratio-1-1_global_style">
                  <img v-if="about_img2" :src="about_img2" class="uploadImg" />
                  <div v-else class="upload_icon">
                    <svg-icon icon-class="upload" className="gray-divide" style="width: 3rem; height: 3rem"></svg-icon>
                    <p class="text-gray-400 text-xs">點擊上傳或拖曳圖片</p>
                  </div>
                  <vs-input type="file" @change="uploadImg($event, 'about_img2')" accept="image/.png,.jpg,.jpeg,.gif,.PNG,.JPG,.JPEG,.GIF" class="img_input" />
                </div>
                <ValidationProvider mode="aggressive" name="關於圖2" placeholder="請選擇關於圖2" rules="required" v-slot="{ errors }" tag="div" class="relative">
                  <vs-input class="w-full" v-model="about_img2" style="display:none;"/>
                  <errorDiv>{{ errors[0] }}</errorDiv>
                </ValidationProvider>
              </div>
            </div>
            <hr />
            <div>
              <h1 class="title">服務項目參數 <span>*</span></h1>
              <div class="flex">
                <div class="flex">
                  <vs-radio v-model="services_full" val=0>
                    非滿版
                  </vs-radio>
                  <vs-radio v-model="services_full" val=1>
                    滿版
                  </vs-radio>
                </div>
                <div class="flex w-24 ml-3">
                  <vs-select v-model="services_col"  color="success">
                    <vs-option v-for="item in def_services_col" :label="item.id" :value="item.id" :key="item.id">{{ item.id }}</vs-option>
                  </vs-select><p class="pt-3">行</p>
                </div>
                <div class="flex w-24 ml-3">
                  <vs-select v-model="services_row"  color="success">
                    <vs-option v-for="item in def_row" :label="item.id" :value="item.id" :key="item.id">{{ item.id }}</vs-option>
                  </vs-select><p class="pt-3">列</p>
                </div>
              </div>
            </div>
            <hr />
            <div>
              <h1 class="title">物件特色參數 <span>*</span></h1>
              <div class="flex">
                <div class="flex">
                  <vs-radio v-model="rooms_full" val=0>
                    非滿版
                  </vs-radio>
                  <vs-radio v-model="rooms_full" val=1>
                    滿版
                  </vs-radio>
                </div>
                <div class="flex w-24 ml-3">
                  <vs-select v-model="rooms_col"  color="success" v-if="rooms_full == 0">
                    <vs-option v-for="item in def_rooms_col0" :label="item.id" :value="item.id" :key="item.id">{{ item.id }}</vs-option>
                  </vs-select>
                  <vs-select v-model="rooms_col" ref="rooms_col" color="success" v-else @change="displaySelect($event, 'rooms_col')">
                    <vs-option v-for="item in def_rooms_col1" :label="item.id" :value="item.id" :key="item.id">{{ item.id }}</vs-option>
                  </vs-select>
                  <p class="pt-3">行</p>
                </div>
                <div class="flex w-24 ml-3">
                  <vs-select v-model="rooms_row" color="success">
                    <vs-option v-for="item in def_row" :label="item.id" :value="item.id" :key="item.id">{{ item.id }}</vs-option>
                  </vs-select><p class="pt-3">列</p>
                </div>
              </div>
            </div>
            <hr />
            <div>
              <h1 class="title">影片專區參數 <span>*</span></h1>
              <div class="flex">
                <div class="flex">
                  <vs-radio v-model="videos_full" val=0>
                    非滿版
                  </vs-radio>
                  <vs-radio v-model="videos_full" val=1>
                    滿版
                  </vs-radio>
                </div>
                <div class="flex w-24 ml-3">
                  <div v-if="videos_full == 0">
                    <vs-select v-model="videos_col" color="success">
                      <vs-option v-for="item in def_rooms_col0" :label="item.id" :value="item.id" :key="item.id">{{ item.id }}</vs-option>
                    </vs-select>
                  </div>
                  <div v-else>
                    <vs-select v-model="videos_col" ref="videos_col" color="success" @change="displaySelect($event, 'videos_col')">
                      <vs-option v-for="item in def_rooms_col1" :label="item.id" :value="item.id" :key="item.id">{{ item.id }}</vs-option>
                    </vs-select>
                  </div>
                  <p class="pt-3">行</p>
                </div>
                <div class="flex w-24 ml-3">
                  <vs-select v-model="videos_row"  color="success">
                    <vs-option v-for="item in def_row" :label="item.id" :value="item.id" :key="item.id">{{ item.id }}</vs-option>
                  </vs-select><p class="pt-3">列</p>
                </div>
              </div>
            </div>
            <div class="w-full h-1 bg-gray-400"></div>
            <div>
              <h1 class="title">LineID <span>*</span></h1>
              <ValidationProvider mode="aggressive" name="LineID" placeholder="請輸入LineID" rules="required|max:30" v-slot="{ errors }" tag="div" class="relative">
                <vs-input class="w-full" v-model="lineid" />
                <errorDiv>{{ errors[0] }}</errorDiv>
              </ValidationProvider>
            </div>
            <div>
              <h1 class="title">電話 <span>*</span></h1>
              <ValidationProvider mode="aggressive" name="電話" placeholder="請輸入電話" rules="required|max:30" v-slot="{ errors }" tag="div" class="relative">
                <vs-input class="w-full" v-model="tel" />
                <errorDiv>{{ errors[0] }}</errorDiv>
              </ValidationProvider>
            </div>
            <div>
              <h1 class="title">信箱 <span>*</span></h1>
              <ValidationProvider mode="aggressive" name="信箱" placeholder="請輸入信箱" rules="required|email|max:200" v-slot="{ errors }" tag="div" class="relative">
                <vs-input class="w-full" v-model="email" />
                <errorDiv>{{ errors[0] }}</errorDiv>
              </ValidationProvider>
            </div>
            <div>
              <h1 class="title">地址 <span>*</span></h1>
              <ValidationProvider mode="aggressive" name="地址" placeholder="請輸入地址" rules="required|max:200" v-slot="{ errors }" tag="div" class="relative">
                <vs-input class="w-full" v-model="address" />
                <errorDiv>{{ errors[0] }}</errorDiv>
              </ValidationProvider>
            </div>
            <div>
              <h1 class="title">FB連結 <span>*</span></h1>
              <ValidationProvider mode="aggressive" name="FB連結" placeholder="請輸入FB連結" rules="required|max:200" v-slot="{ errors }" tag="div" class="relative">
                <vs-input class="w-full" v-model="fb_link" />
                <errorDiv>{{ errors[0] }}</errorDiv>
              </ValidationProvider>
            </div>
            <div>
              <h1 class="title">Youtube連結 <span>*</span></h1>
              <ValidationProvider mode="aggressive" name="Youtube連結" placeholder="請輸入Youtube連結" rules="required|max:200" v-slot="{ errors }" tag="div" class="relative">
                <vs-input class="w-full" v-model="youtube_link" />
                <errorDiv>{{ errors[0] }}</errorDiv>
              </ValidationProvider>
            </div>
            <div>
              <h1 class="title">IG連結 <span>*</span></h1>
              <ValidationProvider mode="aggressive" name="IG連結" placeholder="請輸入IG連結" rules="required|max:200" v-slot="{ errors }" tag="div" class="relative">
                <vs-input class="w-full" v-model="ig_link" />
                <errorDiv>{{ errors[0] }}</errorDiv>
              </ValidationProvider>
            </div>
            <div>
              <h1 class="title">官方Line連結 <span>*</span></h1>
              <ValidationProvider mode="aggressive" name="官方Line連結" placeholder="請輸入官方Line連結" rules="required|max:200" v-slot="{ errors }" tag="div" class="relative">
                <vs-input class="w-full" v-model="line1_link" />
                <errorDiv>{{ errors[0] }}</errorDiv>
              </ValidationProvider>
            </div>
            <div>
              <h1 class="title">客服Line連結 <span>*</span></h1>
              <ValidationProvider mode="aggressive" name="客服Line連結" placeholder="請輸入客服Line連結" rules="required|max:200" v-slot="{ errors }" tag="div" class="relative">
                <vs-input class="w-full" v-model="line2_link" />
                <errorDiv>{{ errors[0] }}</errorDiv>
              </ValidationProvider>
            </div>
            <div>
              <h1 class="title">奔馳客服Line連結 <span>*</span></h1>
              <ValidationProvider mode="aggressive" name="奔馳客服Line連結" placeholder="請輸入奔馳客服Line連結" rules="required|max:200" v-slot="{ errors }" tag="div" class="relative">
                <vs-input class="w-full" v-model="line3_link" />
                <errorDiv>{{ errors[0] }}</errorDiv>
              </ValidationProvider>
            </div>
            <div>
              <h1 class="title">加入我們連結 <span>*</span></h1>
              <ValidationProvider mode="aggressive" name="加入我們連結" placeholder="請輸入加入我們連結" rules="required|max:200" v-slot="{ errors }" tag="div" class="relative">
                <vs-input class="w-full" v-model="joinus_link" />
                <errorDiv>{{ errors[0] }}</errorDiv>
              </ValidationProvider>
            </div>
            <div>
              <h1 class="title">營業時間 <span>*</span></h1>
              <ValidationProvider mode="aggressive" name="營業時間" placeholder="請輸入營業時間" rules="required|max:50" v-slot="{ errors }" tag="div" class="relative">
                <vs-input class="w-full" v-model="business_hours" />
                <errorDiv>{{ errors[0] }}</errorDiv>
              </ValidationProvider>
            </div>
            <div class="flex justify-between">
              <div class="w-4"></div>
              <vs-button size="large" ref="saveBtn" :disabled="invalid" @click="saveBtn(invalid)">
                <span class="font-medium">儲存</span>
              </vs-button>
            </div>
          </ValidationObserver>
        </section>
      </main>
    </div>
  </div>
</template>

<script>

export default {
  name: 'home_setting',
  components: {
    
  },
  data() {
    return {
      def_row:[
        {
          id: 1,
        },
        {
          id: 2,
        },
        {
          id: 3,
        },
        {
          id: 4,
        }
      ],
      def_services_col:[
        {
          id: 2,
        },
        {
          id: 3,
        },
        {
          id: 4,
        }
      ],
      def_rooms_col0:[
        {
          id: 3,
        },
        {
          id: 4,
        }
      ],
      def_rooms_col1:[
        {
          id: 2,
        },
        {
          id: 3,
        },
        {
          id: 4,
        }
      ],
      banner1: '',
      banner2: '',
      about_title: '',
      about_descs: '',
      about_img1: '',
      about_img2: '',
      services_full:0,
      services_col:3,
      services_row:1,
      rooms_full:1,
      rooms_col:4,
      rooms_row:1,
      videos_full:0,
      videos_col:3,
      videos_row:1,
      lineid: '',
      tel: '',
      email: '',
      address: '',
      fb_link: '',
      youtube_link: '',
      ig_link: '',
      line1_link: '',
      line2_link: '',
      line3_link: '',
      joinus_link: '',
      business_hours: '',
    }
  },
  watch: {
    rooms_full: function(newValue, oldValue){
      if(newValue == 0 && this.rooms_col == 2){
        this.rooms_col = 3
      }
    },
    videos_full: function(newValue, oldValue){
      if(newValue == 0 && this.videos_col == 2){
        this.videos_col = 3
      }
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.$axios({
        url: `backstage/homes/home_setting`,
        method: 'get',
      }).then((res) => {
        if (res.data.status) {
          this.is_API_loaded = true
          this.banner1 = res.data.data.banner1
          this.banner2 = res.data.data.banner2
          this.about_title = res.data.data.about_title
          this.about_descs = res.data.data.about_descs
          this.about_img1 = res.data.data.about_img1
          this.about_img2 = res.data.data.about_img2
          this.services_full = res.data.data.services_full
          this.services_col = res.data.data.services_col
          this.services_row = res.data.data.services_row
          this.rooms_full = res.data.data.rooms_full
          this.rooms_col = res.data.data.rooms_col
          this.rooms_row = res.data.data.rooms_row
          this.videos_full = res.data.data.videos_full
          this.videos_col = res.data.data.videos_col
          this.videos_row = res.data.data.videos_row
          this.lineid = res.data.data.lineid
          this.tel = res.data.data.tel
          this.email = res.data.data.email
          this.address = res.data.data.address
          this.fb_link = res.data.data.fb_link
          this.youtube_link = res.data.data.youtube_link
          this.ig_link = res.data.data.ig_link
          this.line1_link = res.data.data.line1_link
          this.line2_link = res.data.data.line2_link
          this.line3_link = res.data.data.line3_link
          this.joinus_link = res.data.data.joinus_link
          this.business_hours = res.data.data.business_hours
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.message, 'danger', 3000)
        }
      })
    },
    saveBtn(invalid) {
        this.saveSubmit(`backstage/homes/home_setting`, 'put', invalid);
    },
    saveSubmit(url, method, invalid) {
      if (invalid) return
      const loading = this.$vs.loading({
        target: this.$refs.saveBtn,
        background: 'primary',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })
      // console.log(this.$data)
      this.$axios({
        url: url,
        method: method,
        data: {
          banner1: this.banner1,
          banner2: this.banner2,
          about_title: this.about_title,
          about_descs: this.about_descs,
          about_img1: this.about_img1,
          about_img2: this.about_img2,
          services_full: this.services_full,
          services_col: this.services_col,
          services_row: this.services_row,
          rooms_full: this.rooms_full,
          rooms_col: this.rooms_col,
          rooms_row: this.rooms_row,
          videos_full: this.videos_full,
          videos_col: this.videos_col,
          videos_row: this.videos_row,
          lineid: this.lineid,
          tel: this.tel,
          email: this.email,
          address: this.address,
          fb_link: this.fb_link,
          youtube_link: this.youtube_link,
          ig_link: this.ig_link,
          line1_link: this.line1_link,
          line2_link: this.line2_link,
          line3_link: this.line3_link,
          joinus_link: this.joinus_link,
          business_hours: this.business_hours,
        },
      }).then((res) => {
        loading.close()
        if(res){
           if (res.data.status) {
            this.$myVS.openNoti(res.data.message, '', undefined)
          } else {
            this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.message, 'danger', 3000)
          }
        }
      })
    },
    uploadImg(e, value) {
      if (!e.target.files[0]) return
      if (e.target.files[0].type.indexOf('image') === -1) {
        this.$myVS.openNoti('發生錯誤，請稍候再試', '僅限圖片格式上傳', 'danger', 10000)
        return
      }
      const image = e.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(image)
      reader.onload = (e) => {
        if(value == 'banner1') this.banner1 = e.target.result
        else if(value == 'banner2') this.banner2 = e.target.result
        else if(value == 'about_img1') this.about_img1 = e.target.result
        else if(value == 'about_img2') this.about_img2 = e.target.result
      }
      e.target.value = ''
    },
    displaySelect(e, value) {
      if(value == 'rooms_col') this.$refs.rooms_col.valueLabel = e
      else if(value == 'videos_col') this.$refs.videos_col.valueLabel = e
    }
  },
}
</script>

<style lang="scss" scoped>
.box {
  @apply bg-white rounded-xl p-4 space-y-6;
}

.title {
  @apply text-sm font-medium text-gray-text_dark mb-1;
  span{
    color: #FF0000;
  }
}

.bigTitle {
  @apply text-base font-medium text-gray-text_dark;
}

::v-deep .vs-input__icon {
  left: 1px;
}

.uploadImg {
  @apply absolute top-1/2 transform -translate-y-1/2 object-contain;
  width: 100%;
  height: 100%;
}
.upload {
  @apply max-w-full border-2 rounded-xl border-dashed border-gray-200 overflow-hidden flex items-center justify-center hover:border-gray-divide transition-colors cursor-pointer relative;
}

.upload_icon {
  @apply flex flex-col items-center absolute top-1/2 transform -translate-y-1/2;
}

.img_input {
  @apply border-2 absolute z-10 h-full w-full cursor-pointer opacity-0 top-1/2 transform -translate-y-1/2;
}
</style>

